import { FC, useMemo } from 'react'

import ProductStep from './steps/ProductStep'
import ContactDetailsStep from './steps/ContactDetailsStep'
import ConfirmationStep from './steps/ConfirmationStep'
import { useSelector } from 'shared-redux'
import { selectedBokaServiceState } from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'

interface IBokaServiceStepHandlerProps {}

const BokaServiceStepHandler: FC<IBokaServiceStepHandlerProps> = () => {
  const state = useSelector(selectedBokaServiceState)

  const activeComponent = useMemo(() => {
    return state.steps[state.activeStepIndex]
  }, [state.activeStepIndex, state.steps])

  return (function () {
    switch (activeComponent.id) {
      case 'PRODUCT':
        return <ProductStep />

      case 'CONTACT_DETAILS':
        return <ContactDetailsStep />

      case 'CONFIRMATION':
        return <ConfirmationStep />

      default:
        return null
    }
  })()
}

export default BokaServiceStepHandler
