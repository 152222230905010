import React, { FC } from 'react'
import { BsPatchCheckFill } from 'react-icons/bs'
import { useRouter } from 'next/navigation'
import { Button, Flex, Icon, VStack } from '@chakra-ui/react'

import StepWrapper from './StepWrapper'
import { GrandText } from 'ui'
import { useSelector } from 'shared-redux'
import { selectedBokaServiceState } from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'

interface ContactDetailsStepProps {}

const ContactDetailsStep: FC<ContactDetailsStepProps> = () => {
  const state = useSelector(selectedBokaServiceState)
  const router = useRouter()

  return (
    <StepWrapper
      description={`Du har nu fått ett bekräftelse via Epost till ${state.contactForm.email}. Om du inte fått bekräftelse inom 30 minuter, kontakta oss.`}
      title="Boka service direkt">
      <VStack
        alignItems="center"
        justifyContent="center"
        spacing={12}
        w={{
          base: 'full',
          md: 'xl'
        }}>
        <Icon as={BsPatchCheckFill} color="status.success" h={20} w={20} />
        <GrandText fontSize="2xl" fontWeight="bold" textAlign="center">
          Härligt! Du kommer bli kontaktad inom 24 timmar för vidare uppföljning
        </GrandText>

        <Flex justifyContent="center" w="full">
          <Button
            bg="background.mildDark"
            border="background.mildDark"
            minW={200}
            onClick={() => router.push('/')}
            py={1}
            rounded="full">
            Gå till startsidan
          </Button>
        </Flex>
      </VStack>
    </StepWrapper>
  )
}

export default ContactDetailsStep
