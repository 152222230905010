import { Text, TextProps } from '@chakra-ui/react'

export const StenlundsCompanyInfo = (props: TextProps) => {
  return (
    <Text fontSize="sm" {...props}>
      Stenlundsmaskincenter AB
      <br />
      Strömögatan 8<br />
      164 40 Kista
      <br />
      Org.nummer: 556222-4021
    </Text>
  )
}
