import { StackProps, Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandLink, GrandLinkProps } from 'ui'
import { StenlundsCompanyInfo } from '#/src/custom/components/StenlundsCompanyInfo'

const ContactSupportLink = (props: GrandLinkProps) => (
  <GrandLink
    {...props}
    _hover={{
      textDecoration: 'none'
    }}
    textDecoration="underline"
  />
)

const workingHours = [
  ['Måndag – Fredag:', '08:00 – 16:30'],
  ['Lunchstängt:', '12:00 – 12:30'],
  ['Lördag – Söndag:', 'Stängt']
]

const contacts = [
  {
    title: 'Telefon:',
    content: [
      {
        href: 'tel:0842003290',
        text: '08 - 4200 32 90'
      }
    ]
  },
  {
    title: 'E-post:',
    content: [
      {
        href: 'mailto:info@stenlundsprofessional.se',
        text: 'info@stenlundsprofessional.se'
      },
      {
        href: 'mailto:service@stenlundsprofessional.se',
        text: 'service@stenlundsprofessional.se'
      }
    ]
  }
]

export const ContactSupport = (props: StackProps) => {
  return (
    <VStack alignItems="flex-start" gap={6} {...props}>
      <GrandHeading
        containerProps={{
          m: 0
        }}
        fontSize={{
          base: '2xl',
          md: '4xl'
        }}
        title="Kontakta kundtjänst"
      />

      <Table variant="unstyled" size="sm" w="auto">
        <Tbody>
          {workingHours.map(([day, hours], index) => (
            <Tr key={index}>
              <Td pl={0}>{day}</Td>
              <Td>{hours}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Table
        variant="unstyled"
        size="sm"
        w="auto"
        sx={{
          td: {
            fontSize: {
              base: 'md',
              md: 'lg'
            },
            fontWeight: '600',
            verticalAlign: 'top',
            pb: 4
          }
        }}>
        <Tbody>
          {contacts.map(({ title, content }) => (
            <Tr key={title}>
              <Td pl={0}>{title}</Td>

              <Td>
                <VStack alignItems="flex-start" gap={3}>
                  {content.map(({ href, text }, index) => (
                    <ContactSupportLink key={index} href={href}>
                      {text}
                    </ContactSupportLink>
                  ))}
                </VStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <VStack alignItems="flex-start" gap={10}>
        <Text fontSize="md">
          Vi erbjuder servicebesök med 2-timmars intervaller, utförda av certifierade tekniker för
          att säkerställa högsta kvalitet och trygghet. Dessutom ingår garanti på alla reservdelar
          vi installerar, så du kan känna dig säker på ett hållbart resultat.
        </Text>

        <StenlundsCompanyInfo />
      </VStack>
    </VStack>
  )
}
