'use client'
import React, { FC } from 'react'
import { Box, GridItem, SimpleGrid, VStack } from '@chakra-ui/react'
import appConfig from '#/src/appConfig'
import { summaryBoxesCn as cn } from './constants'
import { Ratio, SummaryBoxesWidgetItemType } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { WIDGET_GROUP_CN } from 'storefront-modules/widgets'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'

interface SummaryBoxesWidgetItemComponentProps {
  type: 'left' | 'right'
  item: SummaryBoxesWidgetItemType
  aspectRatio?: Ratio
}

const SummaryBoxesWidgetItemComponent: FC<SummaryBoxesWidgetItemComponentProps> = ({
  type,
  item,
  aspectRatio = '4:3'
}) => {
  const isLink = !!item.pathObj
  const appPaths = useAppPaths()

  return (
    <Box
      as={isLink ? GrandLink : 'div'}
      className={cn('item')}
      w="full"
      {...(isLink
        ? {
            href: item.pathObj ? hrefFromAppPaths(item.pathObj, appPaths) : '/',
            isExternal: item.isExternal,
            'aria-label': item.title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}>
      <SimpleGrid
        className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
        columns={{
          base: 1,
          md: 2
        }}
        spacing={10}
        w="full">
        {!!item.imgUrl && (
          <GridItem
            className={cn('item_img_wrapper')}
            order={type === 'left' ? 1 : 2}
            position="relative"
            w="full">
            <GrandAspectRatio breakpoints={{ base: '16:9', md: aspectRatio }}>
              <GrandImage
                alt={item.title || appConfig.site.title}
                borderRadius="md"
                fill
                objectFit="cover"
                sizes="(min-width: 52em) 50vw, 100vw"
                src={item.imgUrl}
              />

              <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
            </GrandAspectRatio>
          </GridItem>
        )}

        <GridItem
          alignItems="flex-start"
          as={VStack}
          className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)}
          order={type === 'left' ? 2 : 1}
          spacing={{
            base: 4,
            md: 4
          }}>
          {!!item.tag && (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TAG)}
              color="text.mild"
              fontSize={{
                base: 'md',
                sm: 'sm'
              }}
              fontWeight="normal"
              headingTag="h3"
              letterSpacing="7.2px"
              title={item.tag.toUpperCase()}
            />
          )}

          {!!item.title && (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
              fontSize={{
                base: 'xl',
                md: '2xl'
              }}
              fontWeight="medium"
              headingTag="h4"
              title={item.title}
            />
          )}

          {!!item.sub && <HTMLArticle className={cn('content')} content={item.sub} />}
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

export default SummaryBoxesWidgetItemComponent
