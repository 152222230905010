'use client'
import type { FC, RefObject } from 'react'
import { useRef, useEffect } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { GrandHeading, GrandNextLink } from 'ui'
import type { BrandGroup } from 'ecosystem'
import { useBrandsContext } from '../hooks'

interface BrandNavigationItemProps extends StackProps {
  brand: BrandGroup
  currentBrandGroup: string
}

const BrandNavigationItem: FC<BrandNavigationItemProps> = ({
  brand,
  currentBrandGroup,
  ...props
}) => {
  const {
    paths: { brandsUrlPath }
  } = useBrandsContext()
  const brandRef: RefObject<HTMLDivElement> = useRef(null)

  useEffect(() => {
    if (brandRef.current && currentBrandGroup === brand.group)
      brandRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [currentBrandGroup, brand.group])

  return (
    <Stack
      align="stretch"
      borderBottomWidth="1px"
      direction={{
        base: 'column',
        md: 'row'
      }}
      paddingY="4"
      ref={brandRef}
      spacing={10}
      w="100%"
      {...props}>
      <GrandHeading
        color={brand.children?.length ? 'primary' : 'text.mild'}
        containerProps={{
          m: 0,
          flexGrow: 0,
          w: 12,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
        headingTag="h2"
        title={brand.group}
        {...(brand.group === currentBrandGroup && {
          textDecoration: 'underline'
        })}
      />

      <SimpleGrid
        alignItems="center"
        columns={{
          base: 2,
          sm: 3,
          md: 4
        }}
        flexGrow={1}
        paddingLeft={{
          base: 4,
          md: 0
        }}
        spacing={6}
        w="100%">
        {brand.children?.map((b) => (
          <GrandNextLink
            aria-label={b.name}
            href={`${brandsUrlPath}/${b.slug}`}
            key={b.id}
            w="100%">
            <Text cursor="pointer" fontSize="md" isTruncated>
              {b.name}
            </Text>
          </GrandNextLink>
        ))}
      </SimpleGrid>
    </Stack>
  )
}

export default BrandNavigationItem
