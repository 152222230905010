'use client'
import type { FC, RefObject } from 'react'
import { Fragment, useState, useRef, useEffect, useMemo, createRef } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Link, VStack, HStack } from '@chakra-ui/react'
import { GrandText } from 'ui'
import type { BrandGroup } from 'ecosystem'
import BrandNavigationItem from './BrandNavigationItem'

interface IBrandNavigationProps {
  brands: BrandGroup[]
  topGap: FlexProps['h']
}

const BrandNavigation: FC<IBrandNavigationProps> = ({ brands, topGap }) => {
  const brandsWithChildren = useMemo(
    () => brands.filter((brand) => brand.children?.length),
    [brands]
  )
  const [currentBrandGroup, setCurrentBrandGroup] = useState(brandsWithChildren[0]?.group)
  // const selectedBrand = brands.find((item) => item.group === currentBrandGroup)
  const brandRefs: RefObject<Record<string, HTMLDivElement>> = useRef(
    brands.reduce(
      (prev, current) => ({
        ...prev,
        [current.group]: createRef()
      }),
      {}
    )
  )

  useEffect(() => {
    if (brandRefs[currentBrandGroup as keyof typeof brandRefs]?.current)
      brandRefs[currentBrandGroup as keyof typeof brandRefs]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
  }, [currentBrandGroup])

  return (
    <VStack flexGrow={1} spacing={10}>
      <HStack
        bg="background.default"
        justify="center"
        left={0}
        position="sticky"
        py={{ base: 2, md: 6 }}
        spacing={{
          base: 2,
          md: '0.4rem'
        }}
        top={topGap}
        w="100%"
        wrap="wrap"
        zIndex="1">
        {brands.map((brand) => (
          <Fragment key={brand.group}>
            {brand.children ? (
              <Link
                aria-label={brand.group}
                as={GrandText}
                color={brand.group === currentBrandGroup ? 'primary' : 'text.darkGrey'}
                fontSize={['sm', 'lg', 'xl', '2xl', '3xl']}
                fontWeight="bold"
                onClick={() => {
                  setCurrentBrandGroup(brand.group)
                }}
                p={{ base: 1, md: 2 }}
                textAlign="center"
                w="auto">
                {brand.group}
              </Link>
            ) : (
              <GrandText
                color="text.mild"
                cursor="not-allowed"
                fontSize={['sm', 'lg', 'xl', '2xl', '3xl']}
                fontWeight="bold"
                p={{ base: 1, md: 2 }}
                textAlign="center"
                w="auto">
                {brand.group}
              </GrandText>
            )}
          </Fragment>
        ))}
      </HStack>

      {Boolean(brandsWithChildren.length) && (
        <VStack alignItems="stretch" spacing="0" w="100%">
          {brandsWithChildren.map((brand, index) => (
            <BrandNavigationItem
              // eslint-disable-next-line react/no-array-index-key -- No ID available
              key={`${index}-brand-${brand.group}`}
              {...{ brand, currentBrandGroup }}
            />
          ))}
        </VStack>
      )}
    </VStack>
  )
}

export default BrandNavigation
