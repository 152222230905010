import ProductList, {
  IProductList
} from 'storefront-modules/products/components/product-list/ProductList'

export const CustomProductList = ({
  filterProps: { wrapperProps, ...filterProps },
  ...props
}: IProductList) => {
  return (
    <ProductList
      filterProps={{
        wrapperProps: {
          sx: {
            '.product-filter__show-more-filters, .product-filter__sorting': {
              border: '1px solid var(--chakra-colors-primary)',
              borderRadius: 'md'
            }
          },
          ...wrapperProps
        },
        ...filterProps
      }}
      {...props}
    />
  )
}
