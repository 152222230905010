'use client'
import ContactText from '#/src/custom/widgets/CustomContactWidget/ContactText'
import { GrandSection, IGrandSection } from 'ui'
import { Box, BoxProps, Flex, StackProps } from '@chakra-ui/react'
import CustomContact, { CustomContactProps } from '#/src/custom/components/CustomContact'
import { OverridesContextProvider, OverridesContextType } from 'ui/lib/overrides'
import { ContactUsFormOverrides } from 'forms'

type CustomContactWidgetProps = Partial<IGrandSection> &
  CustomContactProps & {
    containerProps?: BoxProps
    formContainerProps?: StackProps
  }

const textOverrides: ContactUsFormOverrides = {
  contactUsFormName: 'Namn',
  contactUsFormNamePlaceholder: 'Namn',
  contactUsFormDescription: 'Vad behöver du hjälp med? Beskriv ditt ärende',
  contactUsFormDescriptionPlaceholder: 'Beskriv vad vi kan hjälpa dig med'
}

export const CustomContactWidget = ({
  product,
  withDropZone,
  customDescriptionPlaceholder,
  onSuccess,
  submitButtonProps,
  containerProps,
  formContainerProps,
  ...props
}: CustomContactWidgetProps) => {
  return (
    <OverridesContextProvider overrides={textOverrides as OverridesContextType}>
      <GrandSection bg="background.default" py={[0, 0]} sectionId="contact" {...props}>
        <Box w="full" {...containerProps}>
          <Flex direction={{ base: 'column', lg: 'row' }} mx="auto" py={10} w="100%">
            <ContactText />

            <CustomContact
              formVariant="SIMPLE"
              isPureComponent
              {...{
                product,
                withDropZone,
                customDescriptionPlaceholder,
                onSuccess,
                submitButtonProps,
                ...formContainerProps
              }}
            />
          </Flex>
        </Box>
      </GrandSection>
    </OverridesContextProvider>
  )
}
