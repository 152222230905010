'use client'
import { Box, Divider, Stack } from '@chakra-ui/react'
import BokaServiceStepHandler from './BokaServiceStepHandler'
import Header from './Header'
import { ContactSupport } from './ContactSuppport'
import { GrandSection } from 'ui'

const commonStyles = {
  bg: 'background.lightBlue',
  borderRadius: 'sm',
  p: {
    base: 4,
    md: 0
  },
  boxShadow: {
    base: 'md',
    md: 'none'
  }
}

export const BokaService = () => {
  return (
    <GrandSection bg="background.default" sectionId="boka-service">
      <Header />

      <Stack
        bg={{
          base: 'none',
          md: 'background.lightBlue'
        }}
        borderRadius="sm"
        direction={{
          base: 'column',
          md: 'row'
        }}
        divider={
          <Divider
            borderColor="#5767861A"
            display={{
              base: 'none',
              md: 'block'
            }}
            height="auto"
            orientation="vertical"
          />
        }
        flexGrow={1}
        gap="4"
        p={{
          base: 0,
          md: 8
        }}
        w="full">
        <Box flex={1} {...commonStyles}>
          <BokaServiceStepHandler />
        </Box>

        <ContactSupport flex={0.5} {...commonStyles} />
      </Stack>
    </GrandSection>
  )
}
