import type { CustomComponentImplementation } from 'react-formatge/dist/esm/types'
import { useMemo } from 'react'
import type { GrandSelectProps, GrandSelectSimpleOption } from 'ui'
import { GrandSelect } from 'ui'

export type StepFormSelectOption = GrandSelectSimpleOption<string>

type SteFormSelectProps = CustomComponentImplementation<string> &
  Omit<GrandSelectProps<StepFormSelectOption>, 'value' | 'defaultValue'>

export const StepFormSelect = ({
  value,
  onUpdateValue,
  defaultValue,
  options,
  ...props
}: SteFormSelectProps) => {
  const defaultSelectValue = useMemo(() => {
    return defaultValue
      ? {
          label: defaultValue,
          value: defaultValue
        }
      : undefined
  }, [defaultValue])

  return (
    <GrandSelect<StepFormSelectOption>
      defaultValue={defaultSelectValue}
      onChange={(newValue) => {
        onUpdateValue?.(newValue?.value || '')
      }}
      options={options}
      {...props}
    />
  )
}
