'use client'
import type { FC } from 'react'
import type { BrandGroup } from 'ecosystem'
import { GrandSection } from 'ui'
import type { FlexProps } from '@chakra-ui/react'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import BrandNavigation from './BrandNavigation'

export interface BrandsProps {
  brands: BrandGroup[]
  topGap: FlexProps['h']
}

export interface BrandOverrides {
  brandsTitle?: string
}

const Brands: FC<BrandsProps> = ({ brands, topGap }) => {
  const overrides = useOverridesContext<keyof BrandOverrides>()
  return (
    <GrandSection
      contentContainerProps={{
        id: 'brands-component'
      }}
      headingTag="h1"
      sectionId="brands"
      title={overrideText('Varumärken', overrides?.brandsTitle)}>
      <BrandNavigation {...{ brands, topGap }} />
    </GrandSection>
  )
}

export default Brands
