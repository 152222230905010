import React, { useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel, HStack } from '@chakra-ui/react'
import { MdClear } from 'react-icons/md'
import { FileRejection, GrandDropZone, GrandIconButton } from 'ui'

type AttachmentsInputProps = {
  attachments: File[]
  onChange: (attachments: File[]) => void
}

export const AttachmentsInput = ({ attachments, onChange }: AttachmentsInputProps) => {
  const [rejected, setRejected] = useState<FileRejection[]>([])

  const onDropRejected = (files: FileRejection[]) => {
    setRejected(files)
  }

  const clearFileUpload = () => {
    onChange([])
    setRejected([])
  }

  return (
    <FormControl w="100%">
      <HStack h={10} w="100%">
        <HStack flexGrow={1}>
          <FormLabel htmlFor="Attach files" m={0}>
            Bifoga fil
          </FormLabel>
          <FormErrorMessage fontSize="xs" justifyContent="flex-end" textAlign="right" />
        </HStack>
        {(!!attachments.length || !!rejected.length) && (
          <GrandIconButton
            alignItems="center"
            aria-label="clear upload files"
            background=""
            icon={<MdClear />}
            justifyContent="center"
            minW={undefined}
            onClick={clearFileUpload}
            shadow="none"
            size="xs"
            tooltip="Rensa"
          />
        )}
      </HStack>
      <GrandDropZone
        accepted={attachments}
        dropZoneStyles={{ w: '100%', borderRadius: 'md' }}
        onDrop={onChange}
        options={{
          maxFiles: 5,
          maxSize: 200000
        }}
        w="100%"
        {...{ rejected, onDropRejected }}
      />
    </FormControl>
  )
}
