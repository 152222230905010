import { useContext } from 'react'
import type { BrandsContextType } from '../context'
import { BrandsContext } from '../context'

export const useBrandsContext = (): BrandsContextType => {
  const ctx = useContext(BrandsContext)

  if (!ctx) {
    throw Error('No context provider was found. Wrap your app with the BrandsContextProvider')
  }

  return { ...ctx }
}
