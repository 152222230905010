import React, { FC } from 'react'
import { Checkbox, HStack, Stack, VStack } from '@chakra-ui/react'
import { GrandText } from 'ui'

type CheckboxOptionsProps = {
  label: string
  options: { label: string; id: string }[]
  selected: string[]
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckboxOptions: FC<CheckboxOptionsProps> = ({ label, options, selected, onSelect }) => {
  return (
    <VStack spacing={4} w="full">
      <GrandText>{label}</GrandText>
      <Stack
        align="flex-start"
        flexDirection={{
          base: 'column',
          md: 'row'
        }}
        spacing={4}
        w="full">
        {options.map((option) => (
          <HStack key={option.id} spacing={1}>
            <Checkbox
              alignItems="center"
              borderColor="transparent"
              display="flex"
              isChecked={selected.includes(option.id)}
              justifyContent="center"
              name={option.id}
              onChange={onSelect}
              size="lg">
              <GrandText fontSize="sm">{option.label}</GrandText>
            </Checkbox>
          </HStack>
        ))}
      </Stack>
    </VStack>
  )
}

export default CheckboxOptions
