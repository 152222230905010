import { Button, ButtonProps } from '@chakra-ui/react'

type StepNavButtonProps = ButtonProps

export const StepNavButton = (props: StepNavButtonProps) => {
  return (
    <Button
      _disabled={{
        bg: 'text.mild',
        color: 'text.default',
        opacity: 0.4,
        cursor: 'not-allowed'
      }}
      bg="background.mildDark"
      borderColor="background.mildDark"
      minW={126}
      px={12}
      py={1}
      rounded="full"
      {...props}
    />
  )
}
