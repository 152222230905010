import React, { FC, ReactElement } from 'react'
import { StackProps, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandText } from 'ui'

interface StepWrapperProps extends StackProps {
  title: string
  description?: string
  children: ReactElement[] | ReactElement
}

const StepWrapper: FC<StepWrapperProps> = ({ title, description, children, ...props }) => {
  return (
    <VStack spacing={6} w="full" {...props}>
      <GrandHeading
        containerProps={{
          m: 0
        }}
        fontSize={{
          base: '2xl',
          md: '4xl'
        }}
        textAlign={{
          base: 'center',
          md: 'left'
        }}
        {...{ title }}
      />
      {description ? (
        <GrandText
          textAlign={{
            base: 'center',
            md: 'left'
          }}>
          {description}
        </GrandText>
      ) : null}

      {children}
    </VStack>
  )
}

export default StepWrapper
