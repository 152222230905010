import React, { FC, useCallback } from 'react'
import { formValidationRgx, FormWrapper } from 'react-formatge'
import { FormFieldType, FormUpdatePayload } from 'react-formatge/dist/esm/types'
import { ContactDetailsStepFormFields } from '../types'
import { commonStepFormStyles } from './constants'
import { useDispatch, useSelector } from 'shared-redux'
import {
  selectedBokaServiceState,
  updateContactForm
} from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'

interface ContactDetailsStepFormProps {
  error?: string
}

const ContactDetailsStepForm: FC<ContactDetailsStepFormProps> = ({ error }) => {
  const { contactForm } = useSelector(selectedBokaServiceState)
  const dispatch = useDispatch()

  const inputFields: FormFieldType<ContactDetailsStepFormFields>[] = [
    {
      name: 'name',
      componentType: 'input',
      label: 'Namn',
      initialValue: contactForm.name
    },
    {
      name: 'phone',
      componentType: 'input',
      label: 'Telefonnummer',
      initialValue: contactForm.phone,
      validation: {
        required: true
      }
    },
    {
      name: 'email',
      componentType: 'input',
      label: 'Epost',
      initialValue: contactForm.email,
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Wrong email format'
        }
      }
    },
    {
      name: 'address',
      componentType: 'input',
      label: 'Adress',
      initialValue: contactForm.address,
      validation: {
        required: true
      }
    },
    {
      name: 'postalCode',
      componentType: 'input',
      label: 'Postnummer',
      initialValue: contactForm.postalCode,
      validation: {
        required: true
      }
    },
    {
      name: 'locality',
      componentType: 'input',
      label: 'Ort',
      initialValue: contactForm.locality,
      validation: {
        required: true
      }
    }
  ]

  const buttonProps = {
    display: 'none'
  }

  const handleOnUpdateData = useCallback(
    ({ updatedData }: FormUpdatePayload<ContactDetailsStepFormFields>) => {
      if (!Object.values(updatedData).length) {
        return
      }

      dispatch(
        updateContactForm({
          name: updatedData.name ?? contactForm.name,
          phone: updatedData.phone ?? contactForm.phone,
          email: updatedData.email ?? contactForm.email,
          address: updatedData.address ?? contactForm.address,
          postalCode: updatedData.postalCode ?? contactForm.postalCode,
          locality: updatedData.locality ?? contactForm.locality
        })
      )
    },
    [
      contactForm.address,
      contactForm.email,
      contactForm.locality,
      contactForm.name,
      contactForm.phone,
      contactForm.postalCode,
      dispatch
    ]
  )

  return (
    <FormWrapper<ContactDetailsStepFormFields>
      {...commonStepFormStyles}
      onUpdate={handleOnUpdateData}
      {...{ inputFields, buttonProps, error }}
    />
  )
}

export default React.memo(ContactDetailsStepForm)
