export const commonStepFormStyles = {
  w: 'full',
  display: 'grid',
  gridTemplateColumns: {
    base: '1fr',
    md: '1fr 1fr'
  },
  sx: {
    '.rf__input-field-wrapper': {
      w: 'auto',
      mt: 0
    },
    '.rf__input-field-wrapper, .chakra-form-control': {
      '.chakra-input:not(:focus-visible)': {
        borderColor: 'transparent'
      },
      '.chakra-textarea:not(:focus-visible)': {
        borderColor: 'transparent'
      }
    },
    '.chakra-form-control': {
      w: 'auto'
    },
    '.chakra-popover__content': {
      minW: 'auto',
      w: 'auto'
    },
    '> *:last-child': {
      display: 'none'
    }
  }
}
