import React, { FC, useCallback, useMemo } from 'react'
import { FormWrapper } from 'react-formatge'
import { FormFieldType, FormUpdatePayload } from 'react-formatge/dist/esm/types'

import { ProductFormFields } from '../types'

import { commonStepFormStyles } from './constants'
import { StepFormSelect, StepFormSelectOption } from './StepFormSelect'
import { useGetBrands } from 'storefront-modules/brands'
import {
  selectedBokaServiceState,
  updateProductForm
} from '#/src/custom/widgets/CustomWidgetId/BokaService/slice'
import { useDispatch, useSelector } from 'shared-redux'
import type { GrandSelectGroup } from 'ui'
import { Box } from '@chakra-ui/react'

interface ProductStepFormProps {}

const timeOptions: StepFormSelectOption[] = [
  { label: 'Akut', value: 'Akut' },
  { label: 'Första bästa lediga tid', value: 'Första bästa lediga tid' },
  { label: 'Kontakta mig', value: 'Kontakta mig' }
]

const ProductStepForm: FC<ProductStepFormProps> = () => {
  const { data: brands, isLoading } = useGetBrands('/api/get-brands')
  const dispatch = useDispatch()
  const { productForm } = useSelector(selectedBokaServiceState)

  const brandOptions = useMemo<GrandSelectGroup<StepFormSelectOption>[]>(() => {
    return [
      {
        label: 'Other',
        options: [{ label: 'Övrigt / Vet ej', value: 'Övrigt/Vet ej' }]
      },
      {
        label: 'Options',
        options: (brands || []).map(({ name }) => ({
          label: name,
          value: name
        }))
      }
    ]
  }, [brands])

  const inputFields: FormFieldType<ProductFormFields>[] = [
    {
      name: 'brand',
      componentType: 'component',
      label: 'Ange varumärke',
      initialValue: productForm.brand,
      validation: {
        required: true
      },
      component: (
        <StepFormSelect
          // https://github.com/JedWatson/react-select/issues/5459
          instanceId="boka-service-product-form-brand-select"
          isClearable
          isCreatable
          isLoading={isLoading}
          options={brandOptions}
          components={{
            GroupHeading: (props) => (
              <>
                {props.data.label !== 'Other' && (
                  <Box borderTop="1px dashed #ccc" marginBottom={2} />
                )}
              </>
            )
          }}
        />
      )
    },
    {
      name: 'serviceTime',
      componentType: 'component',
      label: 'Önskad servicetid',
      initialValue: productForm.serviceTime,
      component: (
        <StepFormSelect
          // https://github.com/JedWatson/react-select/issues/5459
          instanceId="boka-service-product-form-time-select"
          options={timeOptions}
        />
      ),
      validation: {
        required: true
      }
    },
    {
      name: 'product',
      componentType: 'input',
      label: 'Om möjligt ange artikelnummer / modell / serienummer',
      initialValue: productForm.product,
      textarea: true,
      validation: {
        required: false
      }
    },
    {
      name: 'description',
      componentType: 'input',
      label: 'Beskriv ditt ärende',
      initialValue: productForm.description,
      textarea: true,
      validation: {
        required: false
      }
    }
  ]

  const buttonProps = {
    display: 'none'
  }

  const handleOnUpdateData = useCallback(
    ({ updatedData }: FormUpdatePayload<ProductFormFields>) => {
      if (!Object.values(updatedData).length) {
        return
      }

      dispatch(
        updateProductForm({
          product: updatedData.product ?? productForm.product,
          brand: updatedData.brand ?? productForm.brand,
          description: updatedData.description ?? productForm.description,
          serviceTime: updatedData.serviceTime ?? productForm.serviceTime
        })
      )
    },
    [
      dispatch,
      productForm.brand,
      productForm.description,
      productForm.product,
      productForm.serviceTime
    ]
  )

  return (
    <FormWrapper<ProductFormFields>
      {...commonStepFormStyles}
      onUpdate={handleOnUpdateData}
      {...{ inputFields, buttonProps }}
    />
  )
}

export default React.memo(ProductStepForm)
